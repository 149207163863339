import styles from "./../css/darkModeToggler.module.css"

export default function DarkToggle(props) {
   
   return (
      <button onClick={props.handleClick} className={styles.toggler} aria-label="Activar Modo Noche">
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 472.39 472.39">
      <g className={styles.toggle_sun}>
        <path d="M403.21,167V69.18H305.38L236.2,0,167,69.18H69.18V167L0,236.2l69.18,69.18v97.83H167l69.18,69.18,69.18-69.18h97.83V305.38l69.18-69.18Zm-167,198.17a129,129,0,1,1,129-129A129,129,0,0,1,236.2,365.19Z" />
      </g>
      <g className={styles.toggle_circle}>
        <circle className="cls-1" cx="236.2" cy="236.2" r="103.78" />
      </g>
    </svg>
  </button>
   )
}