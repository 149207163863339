import Intro from "./../components/Intro"
import Separator from "./../components/Separator"
import LoQueSe from "./../components/LoQueSe"
import LoQueHago from "../components/LoQueHago"
import Contacto from "../components/Contacto"

export default function HomePage() {

return (
   <main className="text-gray-200">
      <Intro />
      <Separator color="var(--c9)"/>
      <LoQueSe />
      <Separator color="var(--c10)" bottom={true}/>
      <LoQueHago />
      <Separator color="var(--c9)" bottom={false}/>
      <Contacto/>
      <Separator color="var(--c10)" bottom={true}/>
   </main>
)
}