import Card from "./Card"

export default function LoQueSe() {
   return (
      <section className="bg-bg2">
         <div className="container p-10 mx-auto text-center
         lg:text-left">
            <h2>Lo que sé</h2>
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
            <Card title="Frontend" 
            skills={["HTML5", "CSS3", "JavaScript ES6", "ReactJS", "Tailwind CSS", "Bootstrap"]}/>
            <Card title="Backend" 
            skills={["MySQL", "MongoDB", "PHP", "NodeJS"]}/>
            <Card title="Herramientas" 
            skills={["Github", "Gitlab", ]}/>
            <Card title="Otros" 
            skills={["Debian", "Red Hat", "Arch Linux"]}/>
            </div>
         </div>
      </section>
   )
}