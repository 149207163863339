import './App.css';
import {Routes, Route} from "react-router-dom"
import Parallax from './components/Parallax';
import HomePage from "./pages/HomePage"

function App() {

  return (
    <div className="App">
      <Parallax/>
      <Routes>
        <Route path="/" element={<HomePage /> } />
      </Routes>
      <footer className="bg-b1 text-gray-300">
        <div className="container p-10 mx-auto text-center text-sm">
          Hecho con ❤️ por Ignacio Lacruz
          </div>
      </footer>
    </div>
  );
}

export default App;
