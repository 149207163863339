import styles from "./../css/Project.module.css"
import {FaLink} from "react-icons/fa"

export default function Project(props) {
   const {title, image, description, link, stack, right} = props;

   function getSide() {
      return !right ? ["lg:w-4/12 lg:order-last items-center lg:items-end", "lg:w-8/12"] : 
      ["lg:w-4/12 lg:order-first items-center lg:items-start", "lg:w-8/12"]
   }

   function getStack() {
      if (!stack) return;
      return stack.map(item => <span key={item}>{item}</span>)
   }

   return (
      <>
      <div className={`${styles.Project} flex flex-col lg:flex-row`}>
         <div className={`${getSide()[0]} flex flex-col justify-center space-y-4`}>
            <h3>{title}</h3>
            <p className={styles.Project_description}>{description}</p>
            <div className={styles.Project_links}>
            <a className={styles.Project_icon} href={link}><FaLink/></a>
            </div>
             <p className={
               !right ? `${styles.Project_stack} justify-end` : `${styles.Project_stack}`
               }>{getStack()}</p>
         </div>
         <img className={`${getSide()[1]} lg:col-span-2 mt-5`} src={image} alt={title}/>
      </div>
      <div className={`${styles.line} bg-primary`}/>
      </>
   )
}