import styles from "./../css/Intro.module.css"
import Ignacio from "./../images/profilet.webp"
import {FaEnvelope} from "react-icons/fa"

export default function Intro() {
   return (
      <section className="bg-bg1">
         <div className="container mx-auto text-center
         grid grid-cols-1 gap-10 p-10 lg:grid-cols-2 lg:text-left">
            <div>
               <h1 className="my-0">¡Hola! Soy <span className={styles.Nacho}>Nacho</span></h1>
               <p>Soy un <strong>desarrollador web muy friki</strong> que vive enamorado del Front-End
                  y obsesionado con el rendimiento.
               </p>
               <p>Soy autodidacta y me fascina resolver problemas de formas creativa.</p>
               <p>Me encanta crear cosas y siempre estoy buscando nuevas formas de
                  mejorar mis habilidades y aprender cosas nuevas.
               </p>
            <a className="btn" href="#contacto">
               <FaEnvelope/>Contacto
            </a>
            </div>
            <div className="flex justify-center items-center">
               <img className={styles.profile} src={Ignacio}/>
            </div>
         </div>
      </section>
   )
}