import {ReactComponent as SeparadorTSVG} from "./../images/separador.svg"
import {ReactComponent as SeparadorBSVG} from "./../images/separadorb.svg"

export default function Separator(props) {

   let bg = "";
   if (props.color === "var(--c9)") {
      bg = "var(--c10)"
   } else {
      bg = "var(--c9)"
   }

   return (
      <div className="separador" 
      style={
         {color: `${props.color}`,
         background: `${bg}`}
         }>
        {props.bottom ? <SeparadorBSVG /> : <SeparadorTSVG/>}
      </div>
   )

}