import {FaCode} from "react-icons/fa"
import {FaServer} from "react-icons/fa"
import {FaTools} from "react-icons/fa"
import {FaLinux} from "react-icons/fa"
import styles from "./../css/Card.module.css"

export default function Card(props) {
   const {title, skills} = props

   function getIcons() {
      if (title === "Frontend") return <FaCode/>
      if (title === "Backend") return <FaServer />
      if (title === "Herramientas") return <FaTools />
      if (title === "Otros") return <FaLinux />
   }

   function getSkills() {
      if (!skills) return;
      return skills.map(item => <span key={item}>{item}</span>)
   }

   getSkills(); 
   return (
      <div className="Card flex flex-col items-center">
         <span className={styles.Card_icon}>
            {getIcons()}
         </span>
         <h3 className="my-0">{title}</h3>
            <p className={`${styles.Card_skills}`}>
               {getSkills()}
            </p>
      </div>
   )
}