import {useState} from "react"
import {FaEnvelope, FaGithub, FaLinkedin} from "react-icons/fa"

export default function Contacto() {

   const [formData, setFormData] = useState({
      name: "",
      email: "",
      message: ""
   })

   function handleData(e) {
      const {name, value} = e.target;
      setFormData(prevData => {
         return {
            ...prevData,
            [name]: value
         }
      })
   }

   return (
      <section id="contacto" className="bg-bg2">
         <div className="container p-10 mx-auto text-center
         lg:text-left">
            <div className="flex justify-center">
               <h2>Contacto</h2>
            </div>
            <div className="social">
               <a href="mailto: hola@lacruzwebdev.com">
                  <FaEnvelope/>
               </a>
               <a href="https://github.com/lacruzwebdev">
                  <FaGithub/>
               </a>
               <a href="https://www.linkedin.com/in/ignacio-lacruz-ba018a22b">
                  <FaLinkedin/>
               </a>
            </div>
            <form className="flex flex-col items-center" name="contact" method="post">
               <input type="hidden" name="form-name" value="contact" />
               <p>
                  <label htmlFor="name">Nombre</label>
                  <input type="text" 
                  id="name" 
                  name="name"  
                  onChange={handleData}
                  required />
               </p>
               <p>
                  <label htmlFor="email">Email</label> 
                  <input type="email" 
                  id="email" 
                  name="email" 
                  onChange={handleData}
                  required />
               </p>
               <p>
                  <label htmlFor="message">Mensaje</label> 
                  <textarea id="message" 
                  name="message" 
                  onChange={handleData}
                  required></textarea>
               </p>
               <p>
               <button className="btn">Enviar</button>
               </p>
         </form>
         </div>
      </section>
   )
}