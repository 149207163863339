import {useState, useEffect} from "react"
import styles from "./../css/Parallax.module.css";
import {ReactComponent as Layer1} from "./../images/1.svg"
import {ReactComponent as Layer2} from "./../images/2.svg"
import {ReactComponent as Layer3} from "./../images/3.svg"
import {ReactComponent as Layer4} from "./../images/4.svg"
import {ReactComponent as Layer5} from "./../images/5.svg"
import {ReactComponent as Layer6} from "./../images/6.svg"
import {ReactComponent as Layer7} from "./../images/7.svg"
import {ReactComponent as Layer8} from "./../images/8.svg"
import {ReactComponent as Layer9} from "./../images/9.svg"
import DarkToggle from "./../components/DarkModeToggler"

export default function Parallax() {

   const [scrollPos, setScrollPos] = useState(0)

   useEffect(() => {
      const handleScroll = () => {
         if (window.pageYOffset > 1000) return;
         setScrollPos(window.pageYOffset)
      }
      window.addEventListener('scroll', handleScroll);

      return function cleanup() {
         window.removeEventListener('scroll', handleScroll)
      }

   }, [])

   function toggleDarkMode() {
      document.querySelector(".App").classList.toggle("night");

   }

   function getOffSet(offset) {
      const value = scrollPos * offset;
      return {transform: `translateY(${value}px) translateX(-50%)`}
   }

   return (
      <header className="Wrapper">
         <div className="Parallax">
            <div className="w-full flex justify-center">
               <nav className="container flex justify-end">
               <  DarkToggle handleClick={toggleDarkMode} />
               </nav>
            </div>
            <div className={styles.layer1} style={getOffSet(0.6)}>
               <Layer1 />
            </div>
            <div className={styles.layer2} style={getOffSet(0.45)}>
               <Layer2 />
            </div>
            <div className={styles.layer3} style={getOffSet(0.3)}>
               <Layer3 />
            </div>
            <div className={styles.layer4} style={getOffSet(0.25)}>
               <Layer4 />
            </div>
            <div className={styles.layer5} style={getOffSet(0.25)}>
               <Layer5 />
            </div>
            <div className={styles.layer6} style={getOffSet(0.25)}>
               <Layer6 />
            </div>
            <div className={styles.layer7} style={getOffSet(0.2)}>
               <Layer7 />
            </div>
            <div className={styles.layer8} style={getOffSet(0.15)}>
               <Layer8 />
            </div>
            <div className={styles.layer9}>
               <Layer9 />
            </div>
         </div>
      </header>
   )
}