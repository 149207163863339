import ProyectoImg1 from "./../images/proyecto1.webp"
import ProyectoImg2 from "./../images/proyecto2.webp"
import Project from "./Project"

export default function LoQueHago() {
   return (
      <section className="bg-bg1">
         <div className="container p-10 mx-auto text-center
         lg:text-left">
            <h2 className="text-center lg:text-right">Lo que hago</h2>
            <Project 
            title="Ignacio Lacruz" 
            image={ProyectoImg1}
            description="Mi web de freelance desarrollada para ser rápida, 
            diferente, fácil de mantener y optimizar las conversiones. 99 en PageSpeed."
            link="https://ignaciolacruz.com"
            stack={["Wordpress", "CPT", "CSS", "PHP", "Runcloud"]}
            right={false}/>
            <Project 
            title="Lacruz Webdev" 
            image={ProyectoImg2}
            description="Este mismo portfolio, desarrollado con ReactJS y alojado en Netlify."
            link="https://ignaciolacruz.com"
            stack={["Javascript ES6", "ReactJS", "Netlify Forms", "Tailwind CSS"]}
            right={true}/>
         </div>
      </section>
   )
}